<template>
  <div class="security-check">
    <el-tabs v-model="activeName" ref="tabs" @tab-click="handleClick">
      <el-tab-pane label="安全检查台账" name="first">
        <security-inspection-ledger v-if="activeName == 'first'"></security-inspection-ledger>
      </el-tab-pane>
      <el-tab-pane label="安全检查汇总台账" name="second">
        <security-inspection-summary-ledger v-if="activeName == 'second'"></security-inspection-summary-ledger>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { tabIndex } from '@/utils/local-cache'
  // import apis from './apis.js
  export default {
    name: 'security-check',

    components: {
      SecurityInspectionLedger: () => import('./components/security-inspection-ledger'),
      SecurityInspectionSummaryLedger: () => import('./components/security-inspection-summary-ledger')
    },

    props: {
    },

    data() {
      return {
        activeName: 'first'
      }
    },

    computed: {
      ...mapGetters(['companyId','optionItem']),
    },

    watch: {
    },
    created() {
      if (tabIndex.get()) this.activeName = tabIndex.get()
      else this.activeName = 'first'
    },
    beforeDestroy() {
      this.activeName = ''
      tabIndex.set('')
    },
    mounted() {},

    methods: {
      // 切换tabs
      handleClick() {
        tabIndex.set(this.activeName)
      }
    }
  }
</script>

<style lang="stylus">
</style>
